import { buscarClientes, buscarProjetosSelecao } from '../../../common/resources/akzo-acao-projeto';

export default {
  data() {
    return {
      akzoRelatoriosResource: this.$api.akzoRelatorios(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      camposCustomizadosResources: this.$api.camposCustomizados(this.$resource),

      tiposInstituicao: [],

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'nome_projeto',
            nomCampoId: 'ids_projeto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => buscarProjetosSelecao({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'nomProjeto',
            },
          },
          {
            labelCampo: 'instituicao',
            nomCampoId: 'ids_cliente',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => buscarClientes({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'instituicao_cor',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'ids_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'tipo_de_instituicao',
            nomCampoId: 'tipos_instituicao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: false,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: this.tiposInstituicao,
              })),
              itemValue: 'value',
              itemText: 'label',
            },
          },
        ],
      },
    };
  },
  methods: {
    carregarCamposDinamicos() {
      this.camposCustomizadosResources.listarPorEntidadeAcao()
        .then((response) => {
          this.getTiposInstituicao(response.data);
        }, (err) => {
          this.$error(this, err);
        });
    },
    getTiposInstituicao(result) {
      const campo = result.find((value) => value.labelCampo === 'tipo_instituicao');
      if (campo && campo.desAtributos && campo.desAtributos.value) {
        const item = {};
        Object.assign(item, JSON.parse(campo.desAtributos.value));
        item.lista.forEach((value) => {
          this.tiposInstituicao.push({ label: value, value });
        });
      }
    },
  },
  mounted() {
    this.carregarCamposDinamicos();
  },
};
